import axios from 'axios'

const API = 'carrito-tendero'

const Pedidos = {
    get_clientes(params){
        return axios(`${API}/get-clientes`,{params})
    },
    agregar_pedido(form){
        return axios.post(`${API}/agregar-pedido`,form)
    },
    get_datos_compra(params){
        return axios(`${API}/resumen-compra`,{params})
    },
    get_datos_payu(params){
        return axios(`${API}/informacion-pago`,{params})
    },
    get_cupones(){
        return axios(`cupones/tendero/pedido`)
    },
    seleccionar_cupon(id_cupon){
        return axios.put(`cupones/tendero/aplicar/${id_cupon}`)
    },
    buscar_cupon(codigo){
        return axios.get(`cupones/tendero/buscar/${codigo}`)
    },
    remover_cupon(){
        return axios.put(`cupones/tendero/remover-cupon`)
    },
    actualizar_productos(form){
        return axios.put(`${API}/actualizar-carrito`,form)
    },
    registrarPago(form){
        return axios.put(`pedidos/pagar`,form)
    },
    getFormaPago(){
        return axios.get(`cart-tendero/forma-pago`)
    },
}

export default Pedidos
