<template>
    <section>
        <modal ref="modalPagoEntrega" titulo="Forma de pago" tamano="modal-lg" no-cancelar cerrar @guardar="registrarPago">
            <div class="row text-general">
                <div class="col-12 text-center">
                    Indique de que manera se está pagando este pedido
                </div>
                <div class="col-6 mx-0">
                    <el-radio-group v-model="tipoPago">
                        <div>
                            <el-radio-button class="w-100 px-3 my-2" :label="11" size="small">
                                <i class="icon-comision mr-1" />
                                Pago en Efectivo
                            </el-radio-button>
                        </div>
                        <div>
                            <el-radio-button class="w-100 px-3 my-2" :label="21" size="small">
                                <i class="icon-transferencia_bancaria mr-1" />
                                Pago por Transferencia
                            </el-radio-button>
                        </div>
                        <div>
                            <el-radio-button class="w-100 px-3 my-2" :label="31" size="small">
                                <i class="icon-creditos mr-1" />
                                Pago a Credito
                            </el-radio-button>
                        </div>
                        <div>
                            <el-radio-button class="w-100 px-3 my-2" :label="41" size="small">
                                <i class="icon-mobile" />
                                Pago en Linea
                            </el-radio-button>
                        </div>
                    </el-radio-group>
                </div>
                <div class="col-6 py-5 text-general">
                    <el-radio v-model="radio" :label="11">Pago Total</el-radio>
                    <div class="row mx-0 pl-3 my-2">
                        <p class="f-600 f-20">
                            {{ convertMoneyTendero(resumen.val_total) }}
                        </p>
                    </div>
                    <div v-show="tipoPago == 11 || tipoPago == 21">
                        <el-radio v-model="radio" :label="12">Pago Parcial</el-radio>
                        <div class="row mx-0 pl-3">
                            <money v-model="pagoParcial" class="input-money w-50" :class="{'disabled-div':radio === 11}" v-bind="money" />
                            <p v-show="errorValue" class="f-11 text-danger">El valor maximo a pagar es de {{ resumen.val_total }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import Pedidos from '~/services/pedidos/pedidos-tendero'
import { mapGetters } from 'vuex'
export default {
    components:{
        Money,
    },
    data(){
        return {
            radio:'',
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            value:0,
            tipoPago:'',
            //maxValue:0,
            //errorValue:false,
            pagoParcial:0
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',
            resumen: 'pedidos/pedidos_historial_tendero/resumen',
        }),
        maxValue(){
            return this.resumen.val_total
        },
        errorValue(){
            return this.radio === 12 && this.pagoParcial > this.maxValue
        }
    },
    methods: {
        toggle(){
            this.money.precision = this.$tienda.decimales
            this.tipoPago = 11
            this.radio = 11
            this.pagoParcial = 0
            this.$refs.modalPagoEntrega.toggle();
        },
        async registrarPago(){
            try {
                if(!this.id_pedido || this.errorValue) return;

                let formaPago = 11
                let valor = 0
                if(this.tipoPago == 11){
                    if(this.radio == 11){
                        valor = this.maxValue
                        formaPago = 11
                    }
                    if(this.radio == 12){
                        valor = this.pagoParcial
                        formaPago = 12
                    }
                }

                if(this.tipoPago == 21){
                    console.log('asasas');
                    if(this.radio == 11){
                        valor = this.maxValue
                        formaPago = 21
                    }
                    if(this.radio == 12){
                        console.log('parcial');
                        valor = this.pagoParcial
                        formaPago = 22
                    }
                }

                if(this.tipoPago == 31){
                    valor = this.maxValue
                    formaPago = 31
                }
                if(this.tipoPago == 41){
                    valor = this.maxValue
                    formaPago = 41
                }


                const form = {
                    id_admin_pedido: this.id_pedido,
                    tipo_pago:formaPago,
                    valor: valor
                }


                const {data} = await Pedidos.registrarPago(form)
                this.notificacion('','Reporte de pago actualizado', 'success')
                this.$store.commit('pedidos/pedidos_historial_tendero/set_entrega',data.entrega)
                this.$refs.modalPagoEntrega.toggle();

            } catch (e){
                this.error_catch(e)
            }



        },

    }
}
</script>
<style lang="scss" scoped>


</style>
